import React from 'react';
import { navigate } from 'gatsby';

import Layout from '../../components/MenuLayout';
import PatientNew from '../../components/Patient/PatientNew';

export default () => {
  const onCompleted = (uuid) => {
    navigate(`/patient/${uuid}`);
  };

  return (
    <Layout>
      <PatientNew handleCompleted={onCompleted} />
    </Layout>
  );
};
