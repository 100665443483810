import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Close } from '@material-ui/icons';

import { PatientForm } from './PatientForm';
import { ADD_PATIENT } from '../../graphql/mutations';
import { GET_AUTHORIZED_PATIENTS_FOR_PREVIEW } from '../../graphql/queries';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../componentsUI/SectionBar';
import useStyles from '../../components/Patient/styled/PatientMakeStyles';
import { ScrollableContainer } from '../Common/ScrollableContainer';

export default ({ handleCompleted }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospitalUuid = useSelector((state) => state.hospital && state.hospital.uuid);

  const [serverError, setServerError] = useState(false);
  const [addPatient, { loading: updating }] = useMutation(ADD_PATIENT, {
    onCompleted({ createPatient: patient }) {
      handleCompleted(patient.uuid);
    },
    onError: (error) => {
      if (error && error.graphQLErrors && error.graphQLErrors.length) {
        setServerError(error.graphQLErrors[0].message);
      }
    },
    refetchQueries: [{
      query: GET_AUTHORIZED_PATIENTS_FOR_PREVIEW,
      variables: {
        orderBy: { field: 'SURNAME', direction: 'ASC' },
        hospitalUuid,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }],
    awaitRefetchQueries: true,
  });

  const goBack = () => window.history.back();

  const backButton = { label: t('return'), icon: Close, onClick: goBack };

  const submitAction = (patient, callback) => {
    addPatient({ variables: { patient } })
      .then(callback());
  };

  const initialPatient = {
    hospitalUuid,
    customId: '',
    name: '',
    surname: '',
    email: '',
    birthDate: '',
    identityNumber: '',
    nationality: '',
    insurance: '',
    insuranceNumber: '',
    phone: '',
    address: '',
    gender: '',
  };

  return (
    <>
      <Navbar className={classes.subheader}>
        <SectionBar title="new.patient" back={backButton} />
      </Navbar>
      <Box className={classes.container}>
        <ScrollableContainer>
          <Paper elevation={2} className={classes.wrapper}>
            <PatientForm
              initialValues={initialPatient}
              submitLabel={t('save')}
              submitAction={submitAction}
              serverError={serverError}
              setServerError={setServerError}
              submitting={updating}
              onClose={goBack}
              formName="patient-form-new"
            />
          </Paper>
        </ScrollableContainer>
      </Box>
    </>
  );
};
